import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { MapContainer, Marker, TileLayer, Popup } from 'react-leaflet'
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css'
import MarkerClusterGroup from "react-leaflet-cluster"

const PublicServicesMapDetail = props => {
  const [data, setData] = useState([])

  useEffect(() => {
    axios.get(props.data_url)
      .then(function (response) {
        setData(response.data)
      })
      .catch(function (error) {
        console.log(error);
      })
  }, [])

  const renderServicesFromArea = () => {
    let arr = []

    data.map((service, index) => (
      arr.push(renderMarkers(service, `service-${service.id}-${index}`))
    ))

    return arr.flat(1)
  }

  const renderData = () => {
    return (<MarkerClusterGroup chunkedLoading animate={true}>
      {renderServicesFromArea()}
    </MarkerClusterGroup>)
  }

  const renderMarkers = (service, index) => {
    if (service.coords.length > 0) {
      return (
        <Marker
          key={index}
          // fillColor='#0b9abd'
          // fillOpacity={1.0}
          // weight={3}
          position={service.coords}
          icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })}
        // radius={5}
        // color='#0b9abd'
        >
          <Popup>
            <h5>{service.name}</h5>
            <p>
              <strong>{I18n.t('services.service_content.contact')}: </strong>
              {service.address}
            </p>
            <p>
              <strong>{I18n.t('activerecord.attributes.service.contact_phone')}: </strong>
              {service.contact_phone}
            </p>
            <p>
              <strong>{I18n.t('activerecord.attributes.service.contact_email')}: </strong>
              {service.contact_email}
            </p>
            <p>
              <strong>{I18n.t('activerecord.attributes.service.company_id')}: </strong>
              <a href={`/sit/organizace/${service.company_id}`}>{service.company}</a>
            </p>
            <p><a href={`/sit/sluzby/${service.id}`}>{I18n.t('services.service_content.more')}</a></p>
          </Popup>
        </Marker>
      )
    }
  }

  return (
    <MapContainer
      center={[49.9497244, 15.7950577]}
      zoom={12}
      scrollWheelZoom={true}
      preferCanvas={false}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {data && renderData()}
    </MapContainer>
  )
}

export default PublicServicesMapDetail;