import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { MapContainer, Marker, TileLayer, Popup } from 'react-leaflet'
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css'

const PublicCompanyMap = props => {
  return (
    <MapContainer
      center={[props.lat || 49.9497244, props.lng || 15.7950577]}
      zoom={12}
      scrollWheelZoom={true}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      { props.lat && props.lng &&
        <Marker
          position={[props.lat, props.lng]}
          icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })}
        >
          <Popup>
            <h5>{props.name}</h5>
            <p>
              <strong>{I18n.t('services.service_content.contact')}: </strong>
              {props.address}
            </p>
          </Popup>
        </Marker>
      }
    </MapContainer>
  )
}

export default PublicCompanyMap;