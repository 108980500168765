import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { MapContainer, CircleMarker, TileLayer, Popup, LayersControl, LayerGroup } from 'react-leaflet'
import ReactPopup from 'reactjs-popup';
import 'leaflet/dist/leaflet.css'
import MarkerClusterGroup from "react-leaflet-cluster"

const PublicServicesMap = props => {
  const [filter, setFilter] = useState({ service_type_id: null })
  const [subjects, setSubjects] = useState([])

  useEffect(() => {
    setSubjects([])

    axios.post(props.areas_url, {
      ...filter
    })
      .then(function (response) {
        setSubjects(response.data.subjects)
      })
      .catch(function (error) {
        console.log(error);
      })
  }, [filter])

  const renderServicesFromArea = subject => {
    let arr = []

    subject.services.map((service, index) => (
      arr.push(renderMarkers(service, service.coords, `service-${service.id}-${index}`, subject.color))
    ))

    // area.services.map(service => (
    //   arr.push(renderMarkers(service, service.api_addresses_reverse, 'address', area.color))
    // ))
    // area.services.map(service => (
    //   arr.push(renderMarkers(service, service.api_localities_reverse, 'locality', area.color))
    // ))
    return arr.flat(1)
  }

  const renderAreas = () => {
    return subjects.map((subject, index) => (
      <LayersControl.Overlay name={subject.name} checked key={index}>
        <MarkerClusterGroup chunkedLoading animate={true} key={index}>
          <LayerGroup>
            {renderServicesFromArea(subject)}
          </LayerGroup>
        </MarkerClusterGroup>
      </LayersControl.Overlay>
    ))
  }

  const renderMarkers = (service, coords, type, marker_color) => {
    if (coords.length > 0) {
      return (
        <CircleMarker
          key={type}
          fillColor={marker_color}
          fillOpacity={1.0}
          weight={3}
          center={coords}
          radius={10}
          color={marker_color}
        >
          <Popup>
            <h5>{service.name}</h5>
            <p>
              <strong>{I18n.t('services.service_content.contact')}: </strong>
              {service.address}
            </p>
            <p>
              <strong>{I18n.t('activerecord.attributes.service.contact_phone')}: </strong>
              {service.contact_phone}
            </p>
            <p>
              <strong>{I18n.t('activerecord.attributes.service.contact_email')}: </strong>
              {service.contact_email}
            </p>
            <p>
              <strong>{I18n.t('activerecord.attributes.service.company_id')}: </strong>
              <a href={`/sit/organizace/${service.company_id}`}>{service.company}</a>
            </p>
            <p><a href={`/sit/sluzby/${service.id}`}>{I18n.t('services.service_content.more')}</a></p>
          </Popup>
        </CircleMarker>
      )
    }
  }

  return (
    <>
      <div className='legend' style={{ display: 'flex', justifyContent: 'right', fontSize: '0.6rem' }}>
        <ReactPopup trigger={
          <button style={{ background: 'none', border: 'none' }}>
            <div className='dot ' style={{ background: 'rgba(85, 60, 60, 0.897)', color: '#fff', paddingTop: '3px', fontSize: '0.8em' }}>2</div>
            <div className='ml-md-1 mr-md-2 mt-md-1 text-bold'>Počet služeb</div>
          </button>
        } position="top right">
          <div>Aktuální všech počet služeb v daném mistě. Po kliknutí na ikonu se objeví služby, které jsou na adrese poskytovány.</div>
        </ReactPopup>
        {subjects.map(subject =>
          <ReactPopup key={subject.name} trigger={
            <button style={{ background: 'none', border: 'none' }}>
              <div className='dot ' style={{ background: subject.color }} />
              <div className='ml-md-1 mr-md-2 text-bold'>{subject.name}</div>
            </button>
          } position="top right">
            <div>{subject.legend_description}</div>
          </ReactPopup>
        )}
      </div>


      <MapContainer
        center={[49.9497244, 15.7950577]}
        zoom={15}
        scrollWheelZoom={true}
        preferCanvas={false}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

        <LayersControl position="topright">
          {subjects && renderAreas()}
        </LayersControl>
      </MapContainer>
    </>
  )
}

export default PublicServicesMap;